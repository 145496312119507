import { RTE, FroalaEditor, initPreview } from '@eduvo/rte'
const customToolbarButtons = {
  moreParagraph: {
    buttons: ['custom-dropdown', 'insertTable', 'insertVideo', 'insertFile', 'insertEmbeddedIframe'],
    buttonsVisible: 1,
    align: 'left',
  },
  moreText: {
    buttons: ['bold', 'italic', 'strikeThrough', 'underline', 'insertHeading', 'quoteToggle', 'insertLink', 'insertColors', 'fontFamily', 'subscript', 'superscript', 'inlineClass', 'clearFormatting'],
    buttonsVisible: 8,
    align: 'left',
  },
  moreRich: {
    buttons: ['formatUL', 'formatOL', 'alignCustom', 'insertHR', 'rightToLeft', 'fullscreen', 'html'],
    buttonsVisible: 18,
  },
  moreMisc: {
    buttons: ['undo', 'redo'],
    align: 'right',
    buttonsVisible: 2,
  },
}

const fontFamily = {
  'Arial,sans-serif': 'Arial',
  'Helvetica,sans-serif': 'Helvetica',
  'Georgia,serif': 'Georgia',
  "'Times New Roman',Times,serif": 'Times New Roman',
  'Courier New,Courier,monospace': 'Monospace',
}

FroalaEditor.DefineIconTemplate('vector_icons2', '<svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" class="fr-svg"><path d="[PATH]" [OPTIONS]></path></svg>')

FroalaEditor.DefineIcon('html', {
  PATH: 'M4.97521 2.59836C5.36054 2.99618 5.36054 3.64118 4.97521 4.03901L2.88189 6.19955L4.97521 8.36097C5.36054 8.75879 5.36054 9.40379 4.97521 9.80162C4.58988 10.1994 3.96513 10.1994 3.5798 9.80162L0.788998 6.92031C0.403667 6.52249 0.403667 5.87749 0.788998 5.47966L3.5798 2.59836C3.96513 2.20053 4.58988 2.20053 4.97521 2.59836ZM8.02479 2.59836C8.41012 2.20053 9.03487 2.20053 9.4202 2.59836L12.211 5.47966C12.5963 5.87749 12.5963 6.52249 12.211 6.92031L9.4202 9.80162C9.03487 10.1994 8.41012 10.1994 8.02479 9.80162C7.63946 9.40379 7.63946 8.75879 8.02479 8.36097L10.117 6.19955L8.02479 4.03901C7.66355 3.66605 7.64097 3.07584 7.95706 2.67573L8.02479 2.59836Z',
  template: 'vector_icons2',
},
)
FroalaEditor.DefineIcon('moreText', {
  PATH: 'M8.52637 7.60625L5.46578 1.04278C5.2979 0.682766 4.93096 0.479093 4.55751 0.501703C4.18368 0.479093 3.81674 0.682766 3.64886 1.04278L0.588265 7.60625C0.368938 8.0766 0.572431 8.6357 1.04278 8.85502L1.17655 8.9174C1.6469 9.13673 2.206 8.93324 2.42532 8.46289L3.09353 7.02892H6.02064L6.68932 8.46289C6.90864 8.93324 7.46774 9.13673 7.93809 8.9174L8.07186 8.85502C8.54221 8.6357 8.7457 8.0766 8.52637 7.60625ZM4.55708 3.89038L5.14439 5.14955H3.96978L4.55708 3.89038ZM10.4292 8.60157C10.4292 9.05553 10.796 9.42229 11.2499 9.42229C11.7039 9.42229 12.0707 9.05553 12.0707 8.60157C12.0707 8.14761 11.7039 7.78085 11.2499 7.78085C10.796 7.78085 10.4292 8.14761 10.4292 8.60157ZM11.2499 10.8586C11.7039 10.8586 12.0707 11.2253 12.0707 11.6793C12.0707 12.1332 11.7039 12.5 11.2499 12.5C10.796 12.5 10.4292 12.1332 10.4292 11.6793C10.4292 11.2253 10.796 10.8586 11.2499 10.8586ZM11.2499 4.70314C11.7039 4.70314 12.0707 5.0699 12.0707 5.52386C12.0707 5.97782 11.7039 6.34458 11.2499 6.34458C10.796 6.34458 10.4292 5.97782 10.4292 5.52386C10.4292 5.0699 10.796 4.70314 11.2499 4.70314Z',
  template: 'vector_icons2',
},
)

const init = function (selector, focusHandler) {
  if (!selector) selector = '.froala-rte'

  const $selector = $(selector)
  const placeholderText = $selector.attr('placeholder')

  return new RTE(selector, {
    version: 'full',
    heightMin: 300,
    heightMax: 500,
    toolbarSticky: false,
    charCounterCount: true,
    charCounterMax: 40000,
    fontFamily,
    placeholderText,
    fileUploadURL: '/admin/froala_s3_upload?folder_name=message_template',
    imageUploadURL: '/admin/froala_s3_upload?folder_name=message_template&image=true',
    toolbarButtons: customToolbarButtons,
    additionalToolbarButtons: ['html', 'fontFamily', 'subscript', 'superscript', 'inlineClass', 'clearFormatting'],
    customDropdownOptions: ['insertFile', 'insertImage', 'insertVideo', 'embeddedIframe', 'table'],
    colorsText: ['#877627', '#b95e04', '#d00001', '#d71daa', '#8f11fe', '#0462b9', '#108a10', '#935117', '#666666', '#000000'],
    colorsBackground: ['#faf685', '#fef1db', '#ffe5e4', '#ffe4f7', '#f2ecff', '#e2effc', '#e4f8e2', '#eee2d7', '#f2f2f2'],
    events: {
      focus: () => { focusHandler && focusHandler() },
    },
  })
}

window.froalaEditor = {
  init,

  // Embedding related event initialization
  initPreview,

  getInstance: function (selector) {
    return $(selector)[0]['data-froala.editor']
  },

  setHtml: function (selector, html, sync = true) {
    const editor = this.getInstance(selector)
    editor.html.set(html)
    if (sync) editor.events.trigger('form.submit')
  },
}
